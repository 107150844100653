import Component from '@classes/Component';

export default class UTC extends Component {
  constructor() {
    super({
      element: 'time',
    });

    this.updateParisTime();
    setInterval(this.updateParisTime, 1000);
  }

  updateParisTime() {
    const parisTimeOffset = 0; // CET
    const now = new Date();
    const utc = now.getTime() + now.getTimezoneOffset() * 60000;

    const parisTime = new Date(
      utc + 3600000 * (parisTimeOffset + (this.isDST(now) ? 1 : 0)),
    );

    this.element.innerHTML = parisTime.toLocaleTimeString('fr-FR');
  }

  isDST(d) {
    const jan = new Date(d.getFullYear(), 0, 1);
    const jul = new Date(d.getFullYear(), 6, 1);
    return (
      d.getTimezoneOffset() <
      Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
    );
  }
}
