precision highp float;

uniform float uTime;
uniform vec3 uColor;

varying vec2 vUv;

float noise(vec2 st) {
    return fract(sin(dot(st.xy, vec2(12.9898, 78.233))) * 43758.5453123);
}

float red(float x) {
    return sin(x + uTime) * 0.3 + noise(vec2(x, uTime)) * 0.1;
}

float green(float x) {
    return sin(x + uTime * 1.1) + noise(vec2(x, uTime * 1.1)) * 0.1;
}

float blue(float x) {
    return sin(x + uTime * 1.2) + noise(vec2(x, uTime * 1.2)) * 0.1;
}

float glbBscale = 0.06;

float processFunc(float x, float y) {
    return (1. / sqrt(pow(-(x + y), 2.0))) * glbBscale;
}

void main() {
    vec2 uv = vUv;
    float n = noise(uv * 2.0 + uTime) * 0.5;

    vec2 newUv = uv;
    newUv.x += sin(uv.y * 10.0 + uTime) * 0.05;
    newUv.y += cos(uv.x * 10.0 + uTime) * 0.05;

    vec3 col = vec3(
        processFunc(-red(newUv.x + n), newUv.y),
        processFunc(-green(newUv.x + n), newUv.y),
        processFunc(-blue(newUv.x + n), newUv.y)
    );

    gl_FragColor = vec4(col, 1.0);
}
