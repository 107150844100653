import '@styles/index.scss';
import '@utils/polyfill';

import AutoBind from 'auto-bind';
import FontFaceObserver from 'fontfaceobserver';
import Stats from 'stats.js';

import Detection from '@classes/Detection';
import Responsive from '@classes/Responsive';
import UTC from '@components/UTC';
import Canvas from '@components/Canvas';

class App {
  constructor() {
    if (import.meta.env.DEV && window.location.hash === '#debug') {
      this.createStats();
    }

    AutoBind(this);

    this.mouse = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    };

    this.createResponsive();
    this.createCanvas();
    this.createUTC();

    this.addEventListeners();

    this.onResize();
    this.update();
  }

  /**
   * Stats.
   */
  createStats() {
    this.stats = new Stats();

    document.body.appendChild(this.stats.dom);
  }

  /**
   * Responsive.
   */
  createResponsive() {
    this.responsive = new Responsive();
  }

  /**
   * Canvas.
   */
  createCanvas() {
    this.canvas = new Canvas({
      size: this.responsive.size,
    });
  }

  /**
   * UTC.
   */
  createUTC() {
    this.utc = new UTC();
  }

  onResize() {
    this.responsive.onResize();

    window.requestAnimationFrame(() => {
      if (this.canvas && this.canvas.onResize) {
        this.canvas.onResize(this.responsive);
      }
    });
  }

  /**
   * Loop.
   */
  update() {
    if (this.stats) {
      this.stats.begin();
    }

    if (this.canvas) {
      this.canvas.update();
    }

    if (this.stats) {
      this.stats.end();
    }

    this.frame = window.requestAnimationFrame(this.update);
  }

  /**
   * Listeners.
   */
  addEventListeners() {
    window.addEventListener('resize', this.onResize, { passive: true });

    if (Detection.isMobile) {
      window.oncontextmenu = this.onContextMenu;
    }
  }
}

const montserrat = new FontFaceObserver('Montserrat');

Promise.all([montserrat.load()])
  .then(() => {
    window.APP = new App();
  })
  .catch(() => {
    window.APP = new App();
  });

console.log(
  '%c Looking for something ? 🕵️‍♂️',
  'background: #1a1a1b; color: #f7ecdd; padding: 20px;',
);
