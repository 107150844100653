import UAParser from "ua-parser-js";

class Detection {
  constructor() {
    this.parser = new UAParser();
    this.device = this.parser.getDevice();

    this.type = null;

    switch (this.device.type) {
      case "mobile":
        this.type = "mobile";
        break;

      case "tablet":
        this.type = "tablet";
        break;

      default:
        this.type = "desktop";
        break;
    }

    document.documentElement.classList.add(this.type);

    this.isMobile = this.checkMobile();
    this.isPhone = this.checkPhone();
    this.isTablet = this.checkTablet();
    this.isDesktop = !this.isPhone && !this.isTablet;

    this.isEdge = this.checkEdge();
    this.isFirefox = this.checkFirefox();
    this.isSafari = this.checkSafari();

    if (typeof window.getComputedStyle(document.body).mixBlendMode === "undefined") {
      this.isMixBlendModeUnsupported = true;

      document.documentElement.className += " mix-blend-mode-unsupported";
    }
  }

  isWebGLAvailable() {
    try {
      const canvas = document.createElement("canvas");

      return !!window.WebGLRenderingContext && (canvas.getContext("webgl") || canvas.getContext("experimental-webgl"));
    } catch (e) {
      return false;
    }
  }

  isWebPSupported() {
    if (!this.isWebPChecked) {
      this.isWebPChecked = true;

      const element = document.createElement("canvas");

      if (element.getContext && element.getContext("2d")) {
        this.isWebPCheck = element.toDataURL("image/webp").indexOf("data:image/webp") === 0;
      }
    }

    return this.isWebPCheck;
  }

  checkAppBrowser() {
    const ua = navigator.userAgent || navigator.vendor || window.opera;

    if (ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1 || ua.indexOf("Twitter") > -1) {
      return true;
    }

    return false;
  }

  checkEdge() {
    const browser = this.parser.getBrowser().name;

    const isEdge = browser === "Edge";
    const isNotMobile = !this.isMobile;

    return isEdge && isNotMobile;
  }

  checkFirefox() {
    const browser = this.parser.getBrowser().name;

    const isFirefox = browser === "Firefox";
    const isNotMobile = !this.isMobile;

    return isFirefox && isNotMobile;
  }

  checkSafari() {
    const browser = this.parser.getBrowser().name;

    const isSafari = browser.indexOf("Safari") > -1;
    const isNotMobile = !this.isMobile;

    return isSafari && isNotMobile;
  }

  checkMobile() {
    return this.checkPhone() || this.checkTablet();
  }

  checkPhone() {
    return this.type === "phone";
  }

  checkTablet() {
    return this.type === "tablet";
  }

  check(successCallback, failCallback) {
    if (!this.isWebGLAvailable()) {
      failCallback();
    } else {
      successCallback();
    }
  }
}

const DetectionManager = new Detection();

export default DetectionManager;
