import {
  Renderer,
  Program,
  Mesh,
  Triangle,
  Camera,
  Transform,
  Vec2,
  Post,
} from 'ogl';
import EventEmitter from 'events';

import Clock from '@classes/Clock';

import fragmentShader from '@shaders/plane-fragment.glsl';
import vertexShader from '@shaders/plane-vertex.glsl';

import fragment from '@shaders/post.glsl';

export default class Canvas extends EventEmitter {
  constructor({ size }) {
    super();

    this.clock = new Clock();

    this.sizes = {
      environment: {
        height: 0,
        width: 0,
      },
      ratio: size / 10,
      retina: Math.min(window.devicePixelRatio, 2),
      screen: {
        height: window.innerHeight,
        width: window.innerWidth,
      },
    };

    this.resolution = {
      value: new Vec2(),
    };

    this.x = {
      start: 0,
      distance: 0,
      end: 0,
    };

    this.y = {
      start: 0,
      distance: 0,
      end: 0,
    };

    this.createRenderer();
    this.createCamera();
    this.createScene();
    this.createPost();

    this.onResize(size);
  }

  createRenderer() {
    this.renderer = new Renderer({
      alpha: false,
      dpr: this.sizes.retina,
    });

    this.gl = this.renderer.gl;
    this.gl.clearColor(1, 1, 1, 0);

    document.body.appendChild(this.gl.canvas);
  }

  createCamera() {
    this.camera = new Camera(this.gl);
    this.camera.position.z = 5;
  }

  createScene() {
    this.scene = new Transform();

    const geometry = new Triangle(this.gl);

    this.program = new Program(this.gl, {
      vertex: vertexShader,
      fragment: fragmentShader,

      uniforms: {
        uTime: { value: 0 },
      },
    });

    const mesh = new Mesh(this.gl, { geometry, program: this.program });
    mesh.setParent(this.scene);
  }

  createPost() {
    this.post = new Post(this.gl);

    this.pass = this.post.addPass({
      fragment,
      uniforms: {
        uResolution: this.resolution,
      },
    });
  }

  /**
   * Resize.
   */
  onResize({ size }) {
    this.sizes.screen = {
      height: window.innerHeight,
      width: window.innerWidth,
    };

    this.renderer.setSize(this.sizes.screen.width, this.sizes.screen.height);

    this.camera.perspective({
      aspect: this.gl.canvas.width / this.gl.canvas.height,
    });

    const fov = this.camera.fov * (Math.PI / 180);
    const height = 2 * Math.tan(fov / 2) * this.camera.position.z;
    const width = height * this.camera.aspect;

    this.sizes.ratio = size / 10;

    this.sizes.environment = {
      height,
      width,
    };

    this.post.resize();

    this.resolution.value.set(this.gl.canvas.width, this.gl.canvas.height);
  }

  onTouchDown({ x, y }) {
    this.x.start = x;
    this.y.start = y;

    this.isDown = true;

    const values = {
      x: this.x,
      y: this.y,
    };
  }

  onTouchMove({ x, y }) {
    this.x.end = x;
    this.y.end = y;

    const values = {
      x: this.x,
      y: this.y,
    };

    if (!this.isDown) {
      return;
    }
  }

  onTouchUp({ x, y }) {
    this.isDown = false;

    this.x.end = x;
    this.y.end = y;

    const values = {
      x: this.x,
      y: this.y,
    };
  }

  /**
   * Update.
   */
  update() {
    this.clock.update();

    this.program.uniforms.uTime.value += this.clock.delta;

    this.post.render({
      scene: this.scene,
      camera: this.camera,
    });
  }
}
