export default class Clock {
  /**
   * Constructor
   */
  constructor() {
    this.start = performance.now() / 1000;
    this.current = this.start;
    this.elapsed = 0;
    this.delta = 16 / 1000;
    this.playing = true;
  }

  play() {
    this.playing = true;
  }

  pause() {
    this.playing = false;
  }

  /**
   * Tick
   */
  update() {
    const current = performance.now() / 1000;

    this.delta = current - this.current;
    this.elapsed += this.playing ? this.delta : 0;
    this.current = current;

    if (this.delta > 60 / 1000) {
      this.delta = 60 / 1000;
    }
  }
}
